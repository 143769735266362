import React from 'react';
import { graphql } from 'gatsby';
import * as PropTypes from 'prop-types';
import Layout from '../components/Layout/Layout';
import SEO from 'components/SEO/SEO';
import PrivacyRightsContainer from '../components/PrivacyRightsContainer/PrivacyRightsContainer';

const propTypes = {
  data: PropTypes.object.isRequired,
};


class PrivacyRightsTemplate extends React.Component {

  render() {
    const { data, location } = this.props;
    return (
        <Layout location={location} type="standard"> 
        <SEO meta={data.privacyRightsDataYaml.metaTags} title={data.privacyRightsDataYaml.title} />  
        <PrivacyRightsContainer data={data.privacyRightsDataYaml.privacyRightsContainer} />
      </Layout>
    );
  }
}
PrivacyRightsTemplate.propTypes = propTypes;

export default PrivacyRightsTemplate;

export const pageQuery = graphql`
  query PrivacyRightsQuery($id: String!) {
    privacyRightsDataYaml(id: { eq: $id }) {
      id
      title
      metaTags {
        name
        content
      }
      privacyRightsContainer {
        companyIdentifier
        homeLink {
            text
            url
        }
        errorMessage
        loadingTitle
        deleteButtonTitle
        viewButtonTitle
      }
    }
  }
`;
