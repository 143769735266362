import React from 'react';
import {
  PRIVACY_RIGHTS_PATH,
  PRIVACY_RIGHTS_URL,
  SEGMENT_WRITE_KEY
} from 'components/Shared/Constants';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import './PrivacyRightsContainer.scss';


class PrivacyRightsContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: false,
      loading: true
    }
  }

  loadScript(src) {
    return new Promise((resolve, reject) => {
      let body = document.getElementsByTagName('body')[0],
          tag = document.createElement('script');
      tag.type = 'text/javascript';
      tag.async = false;
      tag.setAttribute('id', 'privacy-rights-ccpa-script');
      tag.addEventListener('load', () => resolve(src));
      tag.addEventListener('error', () => reject(src));
      tag.src = src;
      body.appendChild(tag);
      return tag;
    });
  }

  componentDidMount() {
    this.loadScript(PRIVACY_RIGHTS_URL).then(
      () => this.setState({ error: false, loading: false})
    ).catch(
      () => this.setState({ error: true, loading: false})
    );
  }

  render() {
    return (
      <div className="privacy-rights-container">
        <ContentBox>
          <div
            id="privacy-rights-optout"
            data-entity={this.props.data.companyIdentifier}
            data-home-uri={this.props.data.homeLink.url}
            data-home-label={this.props.data.homeLink.text}
            data-privacy-rights-home-uri={PRIVACY_RIGHTS_PATH}
            data-delete-button-label={this.props.data.deleteButtonTitle}
            data-view-button-label={this.props.data.viewButtonTitle}
            data-tracking-service="segment.io"
            data-segment-key={SEGMENT_WRITE_KEY}
            className={this.state.error || this.state.loading ? "hidden" : ""}
          />
          { this.state.error ? (
            <div
              className="error-box"
              dangerouslySetInnerHTML={{
                __html: this.props.data.errorMessage
              }}
            />
          ) : null }
          { this.state.loading ? (
            <div className="loading-box">
              <div className="loading-dual-ring" />
              <div className="loading-text">{this.props.data.loadingTitle}</div>
            </div>
          ) : null }
        </ContentBox>
      </div>
    );
  }
}

export default PrivacyRightsContainer;